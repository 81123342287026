import { makeObservable } from "mobx";
/* eslint-disable @typescript-eslint/no-non-null-assertion */
// Libs

// App
import { observable, computed, action } from "mobx";

export class BaseStore {
    protected isLoadingCount = 0;
    public isLoaded = true;
    public get isLoading() {
        return this.isLoadingCount > 0;
    }

    public constructor() {
        makeObservable<BaseStore, "isLoadingCount" | "setIsLoading" | "unsetIsLoading">(this, {
            isLoaded: observable,
            isLoading: computed,
            isLoadingCount: observable,
            setIsLoading: action,
            unsetIsLoading: action,
        });
    }

    protected setIsLoading = () => {
        this.isLoadingCount++;
        this.isLoaded = false;
    };
    protected unsetIsLoading = () => {
        this.isLoadingCount--;
        if (this.isLoadingCount === 0) {
            this.isLoaded = true;
        }
    };
}

import { KeyValuePair, ModelBase } from "../Models";
import { getHistory, getParentObjectPath, isNullOrEmpty, isNullOrUndefined } from "../Utils";
import { FieldType } from "../Models";
import * as History from "history";
//import { MetadataStorage, Validator, validate, getMetadataStorage, ValidationError } from "class-validator";
//import * as ValidationTypes from "class-validator";
import { createProxy, ObjPathProxy } from "ts-object-path";
import { action, computed, Lambda, makeObservable, observable, observe, runInAction } from "mobx";
import { IModel } from "../Models";
import { IViewModel } from "./IViewModel";
import { get as _get, set as _set, isBoolean as _isBoolean, isString as _isString } from "lodash";
import { createViewModel } from "mobx-utils";
import dot from "dot-object";
import { CoreStoreInstance } from "../Stores";
import _ from "lodash";

export type ValidationResponse = {
    isValid: boolean;
    errorMessage: string;
};
export type Create<T> = new (...args: any[]) => T;
export type Validation = [boolean, string];
type ProtectedActions = "setIsErrored";

interface ICommand<Params = any> {
    execute(...params: Params[]): any;
    canExecute(...params: Params[]): any;
}
export abstract class ViewModelBase<T extends IModel<T> = any> implements IViewModel<T> {
    protected setIsErrored = (state: boolean) => (this.IsErrored = state);
    //protected setErrors = (state: string) => (this.Errors = state);

    private coreLogger = CoreStoreInstance.coreLogger;
    private modelReference: any = null;
    private undoableModel: T = {} as T;
    private modelAtTimeOfCreation: T = {} as T;
    private proxy: T = {} as T;

    public model: T = {} as T;
    public Validator: any = null as any;
    public history: History.History;
    public IsErrored = false;
    //public Errors: string = "";
    public Valid: boolean = false;

    //Wizard Methods
    public stepTitle: string = "Step Title";
    public stepPath: string = "/StepPath";
    public isStepActive: boolean = false;
    public setStepIsActive(isActive: boolean) {
        this.isStepActive = isActive;
    }
    public nextStepCommand?: ICommand;
    public previousStepCommand?: ICommand;
    /////////////////////

    protected constructor(model: T, enableProxy: boolean = true, debugLogging: boolean = true) {
        //model = Reflect.construct(T, []);
        //Object.create(T);
        if (this.coreLogger && debugLogging) {
            this.coreLogger.logDebug(`Viewmodel ${this.constructor.name} created`);
        }

        this.history = getHistory();

        makeObservable<ViewModelBase, ProtectedActions>(this, {
            IsErrored: observable,
            Valid: observable,
            isStepActive: observable,
            setIsErrored: action,
            getModel: computed,
            screenWidth: computed,
            isMobile: computed,
            isTablet: computed,
            isDesktop: computed,
            isLoggedIn: computed,
            setValue: action,
            setError: action,
            setValid: action,
            setDirty: action,
            isModelInError: computed,
            isModelDirty: computed,
            setStepIsActive: action,
        });

        if (model) {
            if (enableProxy) {
                this.createNewProxy(model);
            } else {
                this.internalSetModel(model);
            }
            this.modelAtTimeOfCreation = _.cloneDeep(model);
        }
        (window as any)[this.constructor.name] = this;
    }

    public createNewProxy = (model: T) => {
        let self: IViewModel<T> = this;
        this.proxy = new Proxy(model, {
            get(target: any, value: any, receiver: any) {
                let val = Reflect.get(target, value, receiver); // (1)
                return typeof value == "function" ? val.bind(target) : val;
            },
            set(target: any, prop: any, value: any, receiver: any) {
                let newValue = value;
                if (typeof (self as any)["internalBeforeUpdate"] === "function") {
                    let tmpValue = (self as any)["internalBeforeUpdate"](prop, value);
                    if (tmpValue !== null && tmpValue !== undefined) {
                        newValue = tmpValue;
                    }
                }
                let retval = Reflect.set(target, prop, newValue, receiver); // (1)
                if (typeof (self as any)["internalAfterUpdate"] === "function") {
                    (self as any)["internalAfterUpdate"](prop, newValue);
                }
                return retval;
            },
        });
        this.internalSetModel(this.proxy);
    };

    private getType = <T>(TCtor: new (...args: any[]) => T) => {
        return typeof TCtor;
    };

    public beforeUpdate?(fieldName: keyof FieldType<T>, value: any): any;
    public afterUpdate?(fieldName: keyof FieldType<T>, value: any): void;

    private internalBeforeUpdate(fieldName: keyof FieldType<T>, value: any): void {
        if (this.beforeUpdate) {
            this.beforeUpdate(fieldName, value);
        }
    }
    private internalAfterUpdate(fieldName: keyof FieldType<T>, value: any): void {
        if (this.afterUpdate) {
            this.afterUpdate(fieldName, value);
        }
    }

    public get getModel(): T {
        return this.model;
    }

    public get screenWidth(): number {
        return CoreStoreInstance.screenWidth;
        //return document.body.clientWidth;
    }

    public get isMobile(): boolean {
        return CoreStoreInstance.isMobile;
        //return this.screenWidth <= CoreStoreInstance.CoreOptions!.mobileBreakPoint!;
    }

    public get isTablet(): boolean {
        return CoreStoreInstance.isTablet;
        //return this.screenWidth > CoreStoreInstance.CoreOptions.mobileBreakPoint! && this.screenWidth <= CoreStoreInstance.CoreOptions.tabletBreakPoint!;
    }

    public get isDesktop(): boolean {
        return CoreStoreInstance.isDesktop;
        //return this.screenWidth > CoreStoreInstance.CoreOptions!.tabletBreakPoint!;
    }

    public get isLoggedIn(): boolean {
        return CoreStoreInstance.IsLoggedIn;
    }
    private internalSetModel(model: T) {
        this.model = model;
    }

    public setModel(model: T, reset: boolean = true) {
        if (reset) {
            this.model = model;
        }
        for (let key in model) {
            if (model.hasOwnProperty(key)) {
                if (this.getValue(key as any) instanceof Date) {
                    this.setValue(key as any, new Date(model[key] as any));
                } else {
                    this.setValue(key as any, model[key]);
                }
            }
        }
    }

    public getContext = (): ObjPathProxy<T, T> => {
        return createProxy<T>();
    };

    /// <summary>
    /// Saves a copy of the model to allow undoing changes
    /// </summary>
    public saveModel(): void {
        this.undoableModel = _.cloneDeep(this.model);
    }

    /// <summary>
    /// Reset model to a previous state
    /// </summary>
    public resetModel(): void {
        this.model = _.cloneDeep(this.undoableModel);
        this.undoableModel = {} as T;
    }

    public setValue<TR>(fieldName: keyof FieldType<T>, value: TR) {
        if (!this.model.setValue) {
            this.coreLogger.logWarning(
                "setValue does not exist on Model. Are you sure you have created an instance of the model. IE. new MyModel() or called .toModel() on your DTO",
            );
        }
        this.model.setValue<TR>(fieldName, value);
        this.model.setDirty(fieldName, true);
        this.validateIfInError(fieldName);
    }

    public getValue<TR>(fieldName: keyof FieldType<T>): TR {
        if (!this.model.setValue) {
            this.coreLogger.logWarning(
                "getValue does not exist on Model. Are you sure you have created an instance of the model. IE. new MyModel() or called .toModel() on your DTO",
            );
        }
        let value = _get(this.model, fieldName as any);
        if (value === null) {
            if (_isString(value)) {
                (value as any as string) = "";
            } else if (_isBoolean(value)) {
                (value as any as boolean) = false;
            }
            this.model.setValue(fieldName, value);
        }
        return value;
    }

    public setError(fieldName: keyof FieldType<T>, value: string) {
        this.model.setError(fieldName, value);
    }

    public getError(fieldName: keyof FieldType<T>): string {
        let path = getParentObjectPath(fieldName as any, "Errors");
        return _get(this.model, path);
    }

    public getErrors(): KeyValuePair[] {
        let errors: KeyValuePair[] = [];
        for (let field in this.model) {
            if (this.model.hasOwnProperty(field)) {
                let error = this.getError(field as any);
                if (error) {
                    errors.push({ key: field, text: error });
                }
            }
        }
        return errors;
    }

    public setValid(fieldName: keyof FieldType<T>, value: boolean): void {
        this.model.setValid(fieldName, value);
    }

    public getValid(fieldName: keyof FieldType<T>): boolean {
        let path = getParentObjectPath(fieldName as any, "Valid");
        return _get(this.model, path);
        //return this.model.Valid[fieldName];
    }

    public setDirty(fieldName: keyof FieldType<T>, value: boolean): void {
        this.model.setDirty(fieldName, value);
    }

    public getDirty(fieldName: keyof FieldType<T>): boolean {
        let path = getParentObjectPath(fieldName as any, "Dirty");
        return _get(this.model, path);
        //return this.model.Dirty[fieldName];
    }

    // @action
    // public setTouched(fieldName: keyof FieldType<T> | string, value: boolean): void {
    //     this.model.setTouched(fieldName, value);
    // }

    // public getTouched(fieldName: keyof FieldType<T> | string): boolean {
    //     return this.model.getTouched(fieldName);
    //}

    public get isModelInError(): boolean {
        let target = dot.dot(this.model);
        let errors = 0;
        for (let prop in target) {
            if (prop.indexOf("Errors.") < 0 && prop.indexOf("Dirty.") < 0 && prop.indexOf("Valid.") < 0) {
                if (prop != "getParentObjectPath") {
                    let err = this.getValid(prop as any);
                    if (!err) {
                        errors++;
                    }
                }
            }
        }
        return errors > 0;
    }

    public get isModelDirty(): boolean {
        let target = dot.dot(this.model);
        let dirty = 0;
        for (let prop in target) {
            if (prop.indexOf("Errors.") < 0 && prop.indexOf("Dirty.") < 0 && prop.indexOf("Valid.") < 0) {
                if (prop != "getParentObjectPath") {
                    let d = this.getDirty(prop as any);
                    if (d) {
                        dirty++;
                    }
                }
            }
        }
        return dirty > 0;
    }

    // public get isModelTouched(): boolean {
    //     let target = dot.dot(this.model);
    //     let touched = 0;
    //     for (let prop in target) {
    //         if (prop.indexOf("Errors.") < 0 && prop.indexOf("Dirty.") < 0 && prop.indexOf("Touched.") < 0 && prop.indexOf("Valid.") < 0) {
    //             if (prop != "getParentObjectPath") {
    //                 let d = this.getTouched(prop as any);
    //                 if (d) {
    //                     touched++;
    //                 }
    //             }
    //         }
    //     }
    //     return touched > 0;
    // }
    public setValidator = (validator: any): void => {
        setTimeout(() => {
            this.Validator = validator;
        }, 0);
    };

    public isModelValid = (showInfoBarOnValidationFailure: boolean = true, recurse: boolean = false): boolean => {
        if (!this.Validator) {
            this.coreLogger.logInformation("No validator found. Returning true");
            return true;
        }

        let valid = true;
        const result = this.Validator.validate(this.model);

        for (const fieldName of Object.keys(this.model)) {
            if (recurse && this.model[fieldName] instanceof ViewModelBase) {
                (this.model[fieldName] as ViewModelBase).isModelValid();
            }
            if (fieldName.indexOf("Errors") < 0 && fieldName.indexOf("Dirty") < 0 && fieldName.indexOf("Valid") < 0) {
                this.model.setError(fieldName, result[fieldName]);
                if (result[fieldName]) {
                    this.model.setValid(fieldName, false);
                    valid = false;
                    this.coreLogger.logDebug(`Fieldname "${fieldName}" is not valid ${result[fieldName]}`);
                } else {
                    this.model.setValid(fieldName, true);
                }
            } else {
                this.model.setError(fieldName, "");
            }
        }

        this.setIsErrored(this.isModelInError);

        runInAction(() => {
            this.Valid = valid;
        });

        if (!valid && showInfoBarOnValidationFailure) {
            CoreStoreInstance.ShowInfoBar("Please correct the items below in red before resubmitting", "error");
        }

        return valid;
    };

    public validateIfInError = (fieldName: keyof FieldType<T>): void => {
        const isInError = !isNullOrEmpty(this.getError(fieldName));
        if (isInError) {
            this.isFieldValid(fieldName);
        }
    };

    public isFieldValid = (fieldName: keyof FieldType<T>): Validation => {
        if (this.Validator === null) {
            this.coreLogger.logWarning("isFieldValid has been called with no validator being set. Call setValidator on your ViewModel. Returning true");
            this.model.setError(fieldName, "");
            this.model.setValid(fieldName, true);
            return [true, ""];
            //return [false, "No validator has been specified"];
        }

        const result = this.Validator.validateField(this.model, fieldName);
        this.model.setError(fieldName, result[fieldName]);
        if (result[fieldName]) {
            this.model.setValid(fieldName, false);
        } else {
            this.model.setValid(fieldName, true);
        }
        return result;
    };

    public setIsModelValid(state: boolean) {
        for (let prop in this.model) {
            if (prop.indexOf("Valid") > -1) {
                _set(this.model, prop, state);
            }
        }
    }

    private parseObjectProperties = (obj: any, parse: any) => {
        for (let k in obj) {
            if (typeof obj[k] === "object" && obj[k] !== null) {
                this.parseObjectProperties(obj[k], parse);
            } else if (obj.hasOwnProperty(k)) {
                parse(obj, k);
            }
        }
    };

    public getOwnPropertyDescriptors(obj: any) {
        const result = {} as any;
        for (let key of Reflect.ownKeys(obj)) {
            result[key] = Object.getOwnPropertyDescriptor(obj, key);
        }
        return result;
    }

    // public validateModel = async (): Promise<ValidationError[]> => {
    //     let validated = true;
    //     let message = "";

    //     return await validate(this.model);
    // };
}

class ResponseModel extends ModelBase {
    fromDto(model: any): void {
        for (let key in model) {
            if (model.hasOwnProperty(key)) {
                if (this[key] instanceof Date) {
                    this[key] = new Date(model[key]);
                } else {
                    this[key] = model[key];
                }
            }
        }
    }
    toDto(model: any): void {
        throw new Error("Method not implemented.");
    }
}

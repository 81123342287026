export interface EventArgs {
    eventCategory: string;
    eventAction: string;
    eventLabel?: string;
    eventValue?: number;
}

///
/// sendEvent
///
///https://developers.google.com/analytics/devguides/collection/analyticsjs/events
export const sendEvent = (args: EventArgs) => {
    let ga = (window as any).ga;
    if (ga) {
        //Change the below to whatever the tag is
        ga("gtag_UA_82073010_2.send", "event", args);
    }
};

///
/// set
///
export const set = (path: string, title: string) => {
    let ga = (window as any).ga;
    if (ga) {
        ga("set", "page", path);
    }
};

///
/// pageview
///
export const pageview = (path: string, title?: string): void => {
    let ga = (window as any).ga;
    if (ga) {
        ga("gtag_UA_82073010_2.send", {
            hitType: "pageview",
            page: path,
        });
    }
};

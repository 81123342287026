/** Indicates the severity of a log message.
 *
 * Log Levels are ordered in increasing severity. So `Debug` is more severe than `Trace`, etc.
 */
import { singleton } from "tsyringe";

export enum LogLevel {
    /** Log level for very low severity diagnostic messages. */
    Trace = 0,
    /** Log level for low severity diagnostic messages. */
    Debug = 1,
    /** Log level for informational diagnostic messages. */
    Information = 2,
    /** Log level for diagnostic messages that indicate a non-fatal problem. */
    Warning = 3,
    /** Log level for diagnostic messages that indicate a failure in the current operation. */
    Error = 4,
    /** Log level for diagnostic messages that indicate a failure that will terminate the entire application. */
    Critical = 5,
    /** The highest possible log level. Used when configuring logging to indicate that no log messages should be emitted. */
    None = 6,
}

/** An abstraction that provides a sink for diagnostic messages. */
export interface ILogger {
    /** Called by the framework to emit a diagnostic message.
     *
     * @param {string} message The message.
     */
    //log(logLevel: LogLevel, message: string): void;
    logLevel: LogLevel;
    logCritical(message: string, ...params: any): void;
    logError(message: string, ...params: any): void;
    logWarning(message: string, ...params: any): void;
    logInformation(message: string, ...params: any): void;
    logDebug(message: string, ...params: any): void;
}

export enum LoggerType {
    Console,
    Null,
}

export function createLogger(logger?: LoggerType, prefix: string = "") {
    if (logger === undefined) {
        return new ConsoleLogger(prefix);
    }

    if (logger === null) {
        return NullLogger.instance;
    }

    // if ((logger as ILogger).logDebug) {
    //     return logger as ILogger;
    // }

    return new ConsoleLogger(prefix);
}

/** A logger that does nothing when log messages are sent to it. */
export class NullLogger implements ILogger {
    /** The singleton instance of the {@link @microsoft/signalr.NullLogger}. */
    public static instance: ILogger = new NullLogger();

    private constructor() {}

    /** @inheritDoc */
    // tslint:disable-next-line
    //public log(_logLevel: LogLevel, _message: string): void {}
    logLevel: LogLevel = LogLevel.Information;
    logCritical(message: string, ...params: any): void {}
    logError(message: string, ...params: any): void {}
    logWarning(message: string, ...params: any): void {}
    logInformation(message: string, ...params: any): void {}
    logDebug(message: string, ...params: any): void {}
}

@singleton()
export class ConsoleLogger implements ILogger {
    // Public for testing purposes.
    public logLevel = LogLevel.Information;
    private prefix: string;
    public outputConsole: {
        error(message: any, ...params: any): void;
        warn(message: any, ...params: any): void;
        info(message: any, ...params: any): void;
        log(message: any, ...params: any): void;
    };

    constructor(prefix: string = "") {
        this.outputConsole = console;
        this.prefix = prefix;
    }

    public logCritical(message: string, ...params: any): void {
        if (this.logLevel <= LogLevel.Critical) {
            this.outputConsole.error.apply(this, [`[${new Date().toISOString()}] Critical${this.prefix}: ${message}`, ...params!]);
        }
    }
    public logError(message: string, ...params: any): void {
        if (this.logLevel <= LogLevel.Error) {
            this.outputConsole.error.apply(this, [`[${new Date().toISOString()}] Error${this.prefix}: ${message}`, ...params!]);
        }
    }
    public logWarning(message: string, ...params: any): void {
        if (this.logLevel <= LogLevel.Warning) {
            this.outputConsole.warn.apply(this, [`[${new Date().toISOString()}] Warning${this.prefix}: ${message}`, ...params!]);
        }
    }

    public logInformation(message: string, ...params: any): void {
        if (this.logLevel <= LogLevel.Information) {
            this.outputConsole.info.apply(this, [`[${new Date().toISOString()}] Information${this.prefix}: ${message}`, ...params!]);
        }
    }

    public logDebug(message: string, ...params: any): void {
        if (this.logLevel <= LogLevel.Debug) {
            this.outputConsole.log.apply(this, [`[${new Date().toISOString()}] Debug${this.prefix}: ${message}`, ...params!]);
        }
    }
}

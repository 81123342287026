import { computed, makeObservable, Reaction } from "mobx";
/* eslint-disable @typescript-eslint/no-parameter-properties */
import { action, observable, runInAction } from "mobx";
import * as GoogleAnalytics from "../Utils/App/GoogleAnalyticsUtils";
import { createLogger, LoggerType, LogLevel } from "../Utils/Logger";
import { EventArgs } from "../Utils/App/GoogleAnalyticsUtils";
import * as History from "history";
import { JsxAttribute, JsxElement } from "typescript";
import { PubSub } from "../Utils/EventBus";

export type Action = "PUSH" | "POP" | "REPLACE";
export type infoBarSeverity = "success" | "info" | "warning" | "error";

export const EventBus = new PubSub();

export interface ICoreOptions {
    useLocalStorage?: boolean;
    useSessionStorage?: boolean;
    useAsyncStorage?: boolean;
    logLevel?: LogLevel;
    mobileBreakPoint?: number;
    tabletBreakPoint?: number;
    desktopBreakPoint?: number;
}
export class CoreStore {
    public IsLoggedIn = false;
    public DisplayName = "";
    public screenWidth = 0;
    public _isMobile = false;
    public _isTablet = false;
    public _isDesktop = false;

    public infoBarSeverity: infoBarSeverity = "success";
    public infoBarMessage: any = "";
    public isInfoBarOpen: boolean = false;

    public GlobalHistory: History.History = {} as History.History;
    public CoreOptions: ICoreOptions = {
        useLocalStorage: true,
        useSessionStorage: false,
        useAsyncStorage: false,
        logLevel: LogLevel.Debug,
        mobileBreakPoint: 600,
        tabletBreakPoint: 1280,
        desktopBreakPoint: 1920,
    };

    public coreLogger = createLogger(LoggerType.Console, "{Core}");

    public constructor(coreOptions: any) {
        CoreStoreInstance = this;
        this.CoreOptions = { ...this.CoreOptions, ...coreOptions };

        this.coreLogger.logLevel = this.CoreOptions.logLevel as LogLevel;
        this.coreLogger.logInformation("Creating core store with config options", this.CoreOptions);
        makeObservable(this, {
            IsLoggedIn: observable,
            DisplayName: observable,
            screenWidth: observable,
            _isMobile: observable,
            _isTablet: observable,
            _isDesktop: observable,
            isMobile: computed,
            isTablet: computed,
            isDesktop: computed,
            isInfoBarOpen: observable,
            SetLoggedIn: action,
            SetDisplayName: action,
            ShowInfoBar: action,
            HideInfoBar: action,
        });
    }

    public SetLoggedIn(isLoggedIn: boolean) {
        this.IsLoggedIn = isLoggedIn;
    }

    public SetDisplayName(displayName: string) {
        this.DisplayName = displayName;
    }

    public SetHistory(history: any) {
        this.GlobalHistory = history;
    }

    public ShowInfoBar(message: any, severity: infoBarSeverity) {
        this.infoBarSeverity = severity;
        this.infoBarMessage = message;
        this.isInfoBarOpen = true;
        //GoogleAnalytics.TrackEvent(new EventArgs("Error", error));
    }

    public HideInfoBar() {
        this.isInfoBarOpen = false;
    }

    public Init = () => {
        //console.assert(this.theme.palette, "You must set the theme before initiating. Use SetTheme");
        //return;
        if (window && window.addEventListener) {
            window.addEventListener("resize", () => {
                this.setWidth();
            });
            this.setWidth();
        }
        // EN: For debugging
        //window.MobX = MobX;
    };

    private setWidth = () => {
        runInAction(() => {
            this.screenWidth = document.body.clientWidth;
            this._isMobile = this.screenWidth <= this.CoreOptions.mobileBreakPoint!;
            this._isTablet = this.screenWidth > this.CoreOptions.mobileBreakPoint! && this.screenWidth <= this.CoreOptions.tabletBreakPoint!;
            this._isDesktop = this.screenWidth > this.CoreOptions.tabletBreakPoint!;

            if (this._isMobile) {
                document.body.className = "mobile";
            }
            if (this._isTablet) {
                document.body.className = "tablet";
            }
            if (this._isDesktop) {
                document.body.className = "desktop";
            }
        });
    };

    public get isMobile() {
        return this.screenWidth <= this.CoreOptions.mobileBreakPoint!;
    }
    public get isTablet() {
        return this.screenWidth > this.CoreOptions.mobileBreakPoint! && this.screenWidth <= this.CoreOptions.tabletBreakPoint!;
    }
    public get isDesktop() {
        return this.screenWidth > this.CoreOptions.tabletBreakPoint!;
    }

    public PageView = (path: string, title: string = "") => {
        GoogleAnalytics.pageview(path, "");
    };

    public SendEvent = (args: EventArgs) => {
        GoogleAnalytics.sendEvent(args);
    };
}

export let CoreStoreInstance = {} as CoreStore;

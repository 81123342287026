// AJ: https://developer.mozilla.org/en-US/docs/Web/HTTP/Status
export class HttpError extends Error {
    public readonly httpStatusCode: number;

    public constructor(httpStatusCode: number, message: string) {
        // AJ: https://stackoverflow.com/questions/47941324/inherited-exceptions-and-instanceof
        const targetPrototype = new.target.prototype;
        super(message);
        Object.setPrototypeOf(this, targetPrototype);

        this.httpStatusCode = httpStatusCode;
    }
}

export interface LockForm {
    formType: FormType;
    formRef: string;
    id: string;
    lockedByName: string;
}

export enum FormType {
    Ping,
}
